import { createGlobalStyle } from 'styled-components';
import { TypographyWithVariables } from '@kvika/theme';
import localFont from 'next/font/local';

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    html {
        padding: 0;
        margin: 0;
    }
    
    body {
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-weight: normal;
    }

    ${TypographyWithVariables};
`;

export const aCaslonPro = localFont({
  variable: '--ACaslonPro',
  src: [
    {
      path: '../../public/fonts/ACaslonPro-Regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/ACaslonPro-Semibold.woff2',
      weight: '600',
    },
    {
      path: '../../public/fonts/ACaslonPro-Bold.woff',
      weight: 'bold',
    },
    {
      path: '../../public/fonts/ACaslonPro-Italic.woff',
      weight: 'normal',
      style: 'italic',
    },
  ],
});

export const akzidenzGroteskPro = localFont({
  variable: '--AkzidenzGroteskPro',
  src: [
    {
      path: '../../public/fonts/AkzidenzGroteskPro-Regular.otf',
      weight: 'normal',
      style: 'normal',
    },
    {
      path: '../../public/fonts/AkzidenzGroteskPro-Light.otf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../public/fonts/AkzidenzGroteskPro-Md.otf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../../public/fonts/AkzidenzGroteskPro-Bold.otf',
      weight: 'bold',
      style: 'normal',
    },
    {
      path: '../../public/fonts/AkzidenzGroteskPro-It.otf',
      weight: 'normal',
      style: 'italic',
    },
  ],
});

import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import errorsReducer from './error';
import stringReducer from './string';
import modalReducer from './modal';
import entityReducer from './entity';
import companiesReducer from './companies';
import sessionReducer from './session';
import answerReducer from './answer';

const rootPersistConfig = {
  key: 'general-banking',
  storage,
  blacklist: ['error', 'modal', 'companies'],
  timeout: 100,
  keyPrefix: 'banking',
};

const rootReducer = combineReducers({
  errors: errorsReducer,
  string: stringReducer,
  modal: modalReducer,
  entity: entityReducer,
  companies: companiesReducer,
  session: sessionReducer,
  answer: answerReducer,
});

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

import { ButtonBackgroundType } from '@kvika/button';
import {
  InvestorTypeEnum,
  GeneralBankingOnboardingSchema,
  CapitalMarketsOnboardingSchema,
  ProfessionalCategoryEnum,
  DisruptionTypeEnum,
  DerivativesOnboardingSchema,
  KardioOnboardingSchema,
} from '@kvika/api-types';
import { ErrorResponseData } from '@kvika/api-client';
import { AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

import { GeneralBankingNavigationProps } from '../constants/GeneralBankingNavigationTree';
import { CapitalMarketsNavigationProps } from '../constants/CapitalMarketsNavigationTree';
import { DerivativesNavigationProps } from '../constants/DerivativesNavigationTree';
import { RichTextItem } from '../components/CustomRichText/CustomRichText';
import { KardioNavigationProps } from '../constants/KardioNavigationTree';

export type Maybe<T> = T | null;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Json: any;
};

export type _Linkable = {
  _linkType: Maybe<Scalars['String']>;
};

// eslint-disable-next-line
export type UnknownObject = { [key: string]: any };

export type OnboardingSchema =
  | GeneralBankingOnboardingSchema
  | CapitalMarketsOnboardingSchema
  | DerivativesOnboardingSchema
  | KardioOnboardingSchema;

export interface ErrorData {
  data?: ErrorResponseData;
  status?: number;
  headers?: RawAxiosResponseHeaders | AxiosResponseHeaders;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorResponse = AxiosResponse<ErrorResponseData, any> | undefined;

export enum SelectedTabType {
  INDIVIDUAL_TYPE = 0,
  LEGAL_ENTITY_TYPE = 1,
}

export enum CustomOnboardingLinkType {
  Gold = 'gold',
  HomeGold = 'home_gold',
  HomeBlack = 'home_black',
}

export enum Lang {
  IS = 'is',
  EN = 'en',
  PL = 'pl',
}

export type ModalState = {
  modalContent?: ModalFields;
  onConfirm?(): void;
  onClose?(): void;
  onConfirmButtonStyle?: ButtonBackgroundType;
  onCancelButtonStyle?: ButtonBackgroundType;
  showCloseButton?: boolean;
};

export type NewModalState = {
  modalContent?: ModalFields;
  onConfirm?(): void;
  onClose?(): void;
  onConfirmButtonStyle?: ButtonBackgroundType;
  onCancelButtonStyle?: ButtonBackgroundType;
  showCloseButton?: boolean;
};

export type ValidationErrorDetail = {
  stringKey?: string;
  loc: Array<string>;
  msg?: string;
  questionId: number;
  type?: string;
  personIndex?: number;
  humanMsg?: string;
};

export type StringsForOneLanguage = {
  [key: string]: string;
};

export type StringsDataMap = {
  [key: string]: StringsForOneLanguage;
};

export enum JustifyContentType {
  FlexStart = 'flex-start',
  Center = 'center',
}

export type PreviewData = {
  ref: string;
};

export interface Variables {
  uId?: string;
  lang?: string;
  tags?: string[];
}

export type APIOptions = {
  previewData?: PreviewData;
  variables?: Variables;
};

export enum ErrorType {
  ERROR_SOMETHING_WENT_WRONG = 'ERROR_SOMETHING_WENT_WRONG',
  ERROR_TIME_EXPIRED_HEADER = 'ERROR_TIME_EXPIRED_HEADER',
  ERROR_TIME_EXPIRED_BODY = 'ERROR_TIME_EXPIRED_BODY',
  ERROR_ELECTRONIC_ID_FAILED = 'ERROR_ELECTRONIC_ID_FAILED',
  ERROR_NUMBER_DOES_NOT_HAVE_EID = 'ERROR_NUMBER_DOES_NOT_HAVE_EID',
  ERROR_EMPTY_FIELD = 'ERROR_EMPTY_FIELD',
  ERROR_INVALID_INPUT_IN_FORM = 'ERROR_INVALID_INPUT_IN_FORM',
  ERROR_FILE_SIZE_EXCEEDED = 'ERROR_FILE_SIZE_EXCEEDED',
  ERROR_FORBIDDEN_FILE_EXTENSION = 'ERROR_FORBIDDEN_FILE_EXTENSION',
  ERROR_OCCURRED = 'ERROR_OCCURRED',
  ERROR_USER_CANCELLATION = 'ERROR_USER_CANCELLATION',
  ERROR_USER_ALREADY_SIGNED = 'ERROR_USER_ALREADY_SIGNED',
  ERROR_SOAPRA_MIGRATION = 'ERROR_SOAPRA_MIGRATION',
  ERROR_SYSTEM_UPDATE = 'ERROR_SYSTEM_UPDATE',
  ERROR_SYSTEM_DISRUPTION = 'ERROR_SYSTEM_DISRUPTION',
  ERROR_SORRY_FOR_THE_TROUBLE = 'ERROR_SORRY_FOR_THE_TROUBLE',
}

export enum ServerErrors {
  PhoneNumberHasNoElectronicId = 'No electronic ID associated with the phone',
  NotFound = 'not found',
  UserCancelation = 'User cancellation',
  AlreadySigned = 'You have already signed this document',
  FileLimitExceeded = 'File limit exceeded',
  ForbiddenFileExtension = 'Forbidden file extension',
}

export enum ErrorCodes {
  PhoneNumberHasNoElectronicId = 13,
  UserIsTooYoung = 18,
}

export enum NavigationLinkType {
  Button = 'Button',
  BackButton = 'BackButton',
  HomeDarkGrey = 'HomeDarkGrey',
  HomeGold = 'HomeGold',
}

export type NavigationTitleType = {
  forward_button_title?: string;
  back_button_title?: string;
  route?: string;
};

export interface ChoiceGroupContent {
  id: string;
  nameEN: string;
  nameIS: string;
  choices: ChoiceContent[];
}
export interface ChoiceContent {
  id: string;
  nameEN: string;
  nameIS: string;
  allowTax?: boolean;
}

export type NavigationProps = GeneralBankingNavigationProps &
  CapitalMarketsNavigationProps &
  DerivativesNavigationProps &
  KardioNavigationProps;

type OneOfNavigation =
  | { nextPage: string; getNextPage?: never }
  | { getNextPage: (navigationProps: NavigationProps) => string; nextPage?: never };

type AllNavProps = {
  step?: string;
} & OneOfNavigation;

export type NavigationTree = {
  [key: string]: AllNavProps;
};

export type OnboardingFileUpload = {
  key: string;
  files: File[];
  documentType: NewDocumentType;
  category: string;
  requiresUpload: boolean;
};

export type InvestorUploadContent = {
  buttonText?: string;
  radioButtonCaption: string;
  radioButtonDescription: RichTextItem[];
  documentType: NewDocumentType;
  key: string;
  uploadDescription: RichTextItem[];
  uploadTitle?: string;
  fileUploadRequired: boolean;
  category: ProfessionalCategoryEnum | string;
};

export type FileUploadState = {
  key: string;
  files: File[];
  documentType: NewDocumentType;
  category: string;
  requiresUpload: boolean;
};

export type Category = {
  label: string;
  description: string;
  key: InvestorTypeEnum;
};

export enum OnboardingState {
  BLOCKED,
  COMPLETED,
  INCOMPLETE,
  NOT_STARTED,
  CONFIRMATION_MISSING,
}

export type ServiceStatusMode = {
  type?: DisruptionTypeEnum;
  header?: string;
  message?: string;
};

export enum LoginMethodsEnum {
  ELECTRONIC_ID = 0, // Rafræn skilríki
  ESIM = 1, // Auðkennis appið
}

export type LoginStatusProps = {
  loginRequestToken?: string;
  firstTime: number;
  secondTime: number;
  finalPollingTime: Date;
};

export enum NewDocumentType {
  API_DOCUMENT_TYPE = 'API_DOCUMENT_TYPE',
  INVESTMENTS__RELINQUISH_PROTECTION_RIGHTS = 'INVESTMENTS__RELINQUISH_PROTECTION_RIGHTS',
  KVIKA__PEP = 'KVIKA__PEP',
  ONBOARDING_CAPITAL_MARKETS__AGREEMENT_INDIVIDUAL = 'ONBOARDING_CAPITAL_MARKETS__AGREEMENT_INDIVIDUAL',
  ONBOARDING_CAPITAL_MARKETS__AGREEMENT_COMPANY = 'ONBOARDING_CAPITAL_MARKETS__AGREEMENT_COMPANY',
  ONBOARDING_CAPITAL_MARKETS__PROFESSIONAL_GENERAL = 'ONBOARDING_CAPITAL_MARKETS__PROFESSIONAL_GENERAL',
  ONBOARDING_CAPITAL_MARKETS__PROFESSIONAL_CORPORATION = 'ONBOARDING_CAPITAL_MARKETS__PROFESSIONAL_CORPORATION',
  ONBOARDING_DERIVATIVES__AGREEMENT_INDIVIDUAL = 'ONBOARDING_DERIVATIVES__AGREEMENT_INDIVIDUAL',
  ONBOARDING_DERIVATIVES__AGREEMENT_COMPANY = 'ONBOARDING_DERIVATIVES__AGREEMENT_COMPANY',
  ONBOARDING_GENERAL_BANKING__AGREEMENT_INDIVIDUAL = 'ONBOARDING_GENERAL_BANKING__AGREEMENT_INDIVIDUAL',
  ONBOARDING_GENERAL_BANKING__AGREEMENT_COMPANY = 'ONBOARDING_GENERAL_BANKING__AGREEMENT_COMPANY',
  CORPORATION_IDS = 'corporationIds',
  STOCKS = 'stocks',
  HOLDING_IDS = 'holdingIds',
  GENERAL_IDS = 'generalIds',
}

export type ModalFields = {
  button_text?: string;
  cancel_button_text?: string;
  caption: string;
  confirm_button_text?: string;
  is_blocking: boolean;
  key: string;
  modal_text: string;
};

export type ServiceSelectionType = {
  card_holder_email: string;
  card_holder_name: string;
  card_holder_phone_number: string;
  card_holder_ssn: string;
  card_holder_title: string;
  credit_card: string;
  debit_card: string;
  invalid_email: string;
  invalid_phone_number: string;
  invalid_pin: string;
  maximum_amount_caption: string;
  name_error: string;
  no: string;
  online_bank_caption: string;
  online_bank_title: string;
  payment_card_caption: string;
  payment_card_title: string;
  pin_error_birthdate: string;
  pin_error_length: string;
  pin_error_number_asc_desc: string;
  pin_error_series: string;
  pin_error_ssn: string;
  security_pin: string;
  security_pin_tooltip: string;
  ssn_error: string;
  withdrawal_limit_caption: string;
  withdrawal_limit_error: string;
  withdrawal_limit_error_min: string;
  yes: string;
};

export type SelectedEntitiesType = {
  confirm_information_url: { url: string };
  error_text: string;
  error_title_text: string;
  select_button_text: string;
  select_fund_url: { url: string };
  ssn_text: string;
  try_again_error_text: string;
};

export type OnboardingThankYouType = {
  continue_buy_in_funds: RichTextItem[];
  continue_buy_in_funds_key_text: string;
  continue_buy_in_funds_professional: RichTextItem[];
  continue_buy_in_funds_professional_key_text: string;
  continue_general_banking: RichTextItem[];
  continue_general_banking_key_text: string;
  private_banking_text: RichTextItem[];
};

export type OnboardingPageBodyType = {
  pin_caption: string;
  pin_error: string;
  pin_error_birthdate: string;
  pin_error_length: string;
  pin_error_number_asc_desc: string;
  pin_error_series: string;
  pin_error_ssn: string;
};

export enum DocumentSignType {
  DOCUMENT_SIGNING = 'DOCUMENT_SIGNING',
  DOCUMENT_SIGNING_SUCCEEDED = 'DOCUMENT_SIGNING_SUCCEEDED',
  PEP_WARNING = 'PEP_WARNING',
}

export type NewOnboardingPageBodyLoginPrimary = {
  invalid_phone_number_message: string;
  login_subtitle: string;
  login_title: string;
  phone_number_label: string;
  ssn_label: string;
  signup_key_text?: string;
  signup_link_text?: string;
  signup_text?: string;
  tab_individual: string;
  tab_legal_entity: string;
  login_with_electronic_id: string;
  login_with_audkenni_app: string;
  invalid_ssn_message: string;
};

export enum LoginType {
  LOGIN_PHONE_EID = 'LOGIN_PHONE_EID',
  SIGNUP_REDIRECT = 'SIGNUP_REDIRECT',
  CONTINUE_SIGNUP_REDIRECT = 'CONTINUE_SIGNUP_REDIRECT',
}

export type LeiCodeType = {
  lei_code_not_found_caption: string;
  lei_code_not_found_description: RichTextItem[];
};

export type Banner = {
  logged_in_as: Maybe<Scalars['String']>;
  on_behalf: Maybe<Scalars['String']>;
  switch: Maybe<Scalars['String']>;
};

export type ErrorStringsErrors = {
  key: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
};

export type ErrorStrings = {
  errors: Maybe<Array<ErrorStringsErrors>>;
};

export type OnboardingPageBodyProfessional_Investors_UploadsFields = {
  button_text: Maybe<Scalars['String']>;
  caption: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  checkbox_description: Maybe<Scalars['Json']>;
  document_type: Maybe<Scalars['String']>;
  file_upload_required: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  radio_button_caption: Maybe<Scalars['String']>;
  radio_button_description: Maybe<Scalars['Json']>;
  upload_description: Maybe<Scalars['Json']>;
  upload_title: Maybe<Scalars['String']>;
};

export type OnboardingPageBodyCategorizationFields = {
  category_description: Maybe<Scalars['String']>;
  category_name: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type OnboardingPageBodyNavigationPrimary = {
  back_button_title: Maybe<Scalars['String']>;
  forward_button_link: Maybe<_Linkable>;
  forward_button_title: Maybe<Scalars['String']>;
  route: Maybe<Scalars['String']>;
};

export type OnboardingPageBodyNavigation = {
  fields: Maybe<Array<OnboardingPageBodyNavigationFields>>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<OnboardingPageBodyNavigationPrimary>;
  type: Maybe<Scalars['String']>;
};

export type OnboardingPageBodyNavigationFields = {
  investor_type: Maybe<Scalars['String']>;
  is_company: Maybe<Scalars['Boolean']>;
  link: Maybe<_Linkable>;
};

export type OnboardingPageBodyModalgroup = {
  fields: Maybe<Array<ModalFields>>;
  label: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

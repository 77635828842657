import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CapitalMarketsOnboardingResponseSchema,
  CapitalMarketsOnboardingSchema,
  DerivativesOnboardingResponseSchema,
  DerivativesOnboardingSchema,
  GeneralBankingOnboardingResponseSchema,
  GeneralBankingOnboardingSchema,
  OnboardingType,
  PrivateBankingOnboardingResponseSchema,
  PrivateBankingOnboardingSchema,
  KardioOnboardingSchema,
  KardioOnboardingResponseSchema,
} from '@kvika/api-types';
import { RootState } from '.';
import { logout } from './session';
import { OnboardingState } from '../types/Types';
import { OnboardingStatusState } from '../utils/Utils';

type OnboardingTypeStates = {
  [OnboardingType.GENERAL_BANKING]: OnboardingState | undefined;
  [OnboardingType.CAPITAL_MARKETS]: OnboardingState | undefined;
  [OnboardingType.PRIVATE_BANKING]: OnboardingState | undefined;
  [OnboardingType.DERIVATIVES]: OnboardingState | undefined;
  [OnboardingType.KARDIO]: OnboardingState | undefined;
};

export type AnswerState = {
  onboardingAnswers: {
    [OnboardingType.GENERAL_BANKING]: GeneralBankingOnboardingSchema | undefined;
    [OnboardingType.CAPITAL_MARKETS]: CapitalMarketsOnboardingSchema | undefined;
    [OnboardingType.PRIVATE_BANKING]: PrivateBankingOnboardingSchema | undefined;
    [OnboardingType.DERIVATIVES]: DerivativesOnboardingSchema | undefined;
    [OnboardingType.KARDIO]: KardioOnboardingSchema | undefined;
  };
  // TODO: We should try to merge these two to simplify this but it might require changing the types.
  // Currently there are some things that are only in responses, f.ex. documents, that are never sent as answers
  onboardingResponses: {
    [OnboardingType.GENERAL_BANKING]: GeneralBankingOnboardingResponseSchema | undefined;
    [OnboardingType.CAPITAL_MARKETS]: CapitalMarketsOnboardingResponseSchema | undefined;
    [OnboardingType.PRIVATE_BANKING]: PrivateBankingOnboardingResponseSchema | undefined;
    [OnboardingType.DERIVATIVES]: DerivativesOnboardingResponseSchema | undefined;
    [OnboardingType.KARDIO]: KardioOnboardingResponseSchema | undefined;
  };
  onboardingStatus: OnboardingStatusState;
  currentFlow: OnboardingType | undefined;
  selectedFlows: OnboardingType[];
  completedFlows: OnboardingType[];
  securityPin?: string;
  skipFlowSelection: boolean;
  OnboardingTypeStates: OnboardingTypeStates;
  creditLimit?: string;
};

type SecurityPinPayload = {
  securityPin?: string;
};

type AnswerData =
  | GeneralBankingOnboardingSchema
  | CapitalMarketsOnboardingSchema
  | PrivateBankingOnboardingSchema
  | KardioOnboardingSchema;

type ResponseData =
  | GeneralBankingOnboardingResponseSchema
  | CapitalMarketsOnboardingResponseSchema
  | DerivativesOnboardingResponseSchema
  | KardioOnboardingResponseSchema;

const initialState: AnswerState = {
  onboardingAnswers: {
    [OnboardingType.GENERAL_BANKING]: undefined,
    [OnboardingType.CAPITAL_MARKETS]: undefined,
    [OnboardingType.PRIVATE_BANKING]: undefined,
    [OnboardingType.DERIVATIVES]: undefined,
    [OnboardingType.KARDIO]: undefined,
  },
  onboardingResponses: {
    [OnboardingType.GENERAL_BANKING]: undefined,
    [OnboardingType.CAPITAL_MARKETS]: undefined,
    [OnboardingType.PRIVATE_BANKING]: undefined,
    [OnboardingType.DERIVATIVES]: undefined,
    [OnboardingType.KARDIO]: undefined,
  },
  onboardingStatus: {
    [OnboardingType.GENERAL_BANKING]: {},
    [OnboardingType.CAPITAL_MARKETS]: {},
    [OnboardingType.PRIVATE_BANKING]: {},
    [OnboardingType.DERIVATIVES]: {},
    [OnboardingType.KARDIO]: {},
  },
  currentFlow: OnboardingType.GENERAL_BANKING,
  selectedFlows: [],
  completedFlows: [],
  securityPin: undefined,
  skipFlowSelection: false,
  OnboardingTypeStates: {
    [OnboardingType.GENERAL_BANKING]: undefined,
    [OnboardingType.CAPITAL_MARKETS]: undefined,
    [OnboardingType.PRIVATE_BANKING]: undefined,
    [OnboardingType.DERIVATIVES]: undefined,
    [OnboardingType.KARDIO]: undefined,
  },
};

export const answerSlice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    setOnboardingStatus: (state, action: PayloadAction<OnboardingStatusState>) => {
      return {
        ...state,
        onboardingStatus: action.payload,
      };
    },
    setOnboardingAnswers: (state, action: PayloadAction<AnswerData>) => {
      if (state.currentFlow === undefined) {
        throw new Error('No onboarding flow as been selected');
      }
      const prevAnswers = state.onboardingAnswers[state.currentFlow];
      return {
        ...state,
        onboardingAnswers: {
          ...state.onboardingAnswers,
          [state.currentFlow]: {
            ...prevAnswers,
            ...action.payload,
          },
        },
      };
    },
    setOnboardingResponses: (state, action: PayloadAction<ResponseData>) => {
      if (state.currentFlow === undefined) {
        throw new Error('No onboarding flow as been selected');
      }
      const prevResponses = state.onboardingResponses[state.currentFlow];
      return {
        ...state,
        onboardingResponses: {
          ...state.onboardingResponses,
          [state.currentFlow]: {
            ...prevResponses,
            ...action.payload,
          },
        },
      };
    },
    switchFlows: (state) => {
      const newSelectedFlows = state.selectedFlows.slice(1, state.selectedFlows.length);

      const newFlow = newSelectedFlows[0];

      if (!state.currentFlow) {
        return state;
      }

      return {
        ...state,
        selectedFlows: newSelectedFlows,
        currentFlow: newFlow,
        completedFlows: [...state.completedFlows, state.currentFlow],

        onboardingAnswers: {
          ...state.onboardingAnswers,
          [newFlow]: {
            ...state.onboardingAnswers[newFlow],
            companyInfo: state.onboardingAnswers[state.currentFlow]?.companyInfo,
            amlSurveyAnswerId: state.onboardingAnswers[state.currentFlow]?.amlSurveyAnswerId,
            pin: state.securityPin,
            ...((state.currentFlow === OnboardingType.CAPITAL_MARKETS ||
              state.currentFlow === OnboardingType.PRIVATE_BANKING ||
              state.currentFlow === OnboardingType.DERIVATIVES) &&
              (newFlow === OnboardingType.CAPITAL_MARKETS ||
                newFlow === OnboardingType.PRIVATE_BANKING ||
                newFlow === OnboardingType.DERIVATIVES) && {
                // TODO: See if we can move this into a function
                appropriatenessSurveyAnswerId:
                  state.onboardingAnswers[state.currentFlow]?.appropriatenessSurveyAnswerId,
                investorType: state.onboardingAnswers[state.currentFlow]?.investorType,
                professionalDocuments: state.onboardingAnswers[state.currentFlow]?.professionalDocuments,
              }),
          },
        },
      };
    },
    setCurrentOnboardingFlow: (state, action: PayloadAction<OnboardingType>) => {
      return {
        ...state,
        currentFlow: action.payload,
      };
    },
    setSelectedFlows: (state, action: PayloadAction<OnboardingType[]>) => {
      // Make sure flows are always in same order (lock flow order)
      // NOTE: Do not change order without thorough testing.
      const FLOW_ORDER = [
        OnboardingType.GENERAL_BANKING,
        OnboardingType.PRIVATE_BANKING,
        OnboardingType.DERIVATIVES,
        OnboardingType.CAPITAL_MARKETS,
        OnboardingType.KARDIO,
      ];
      return {
        ...state,
        selectedFlows: action.payload.sort((flowA, flowB) => {
          return FLOW_ORDER.indexOf(flowA) - FLOW_ORDER.indexOf(flowB);
        }),
      };
    },
    setCreditLimit: (state, selectedCredit: PayloadAction<string>) => {
      return {
        ...state,
        creditLimit: selectedCredit.payload,
      };
    },
    updateSecurityPin: (state, action: PayloadAction<SecurityPinPayload>) => {
      return {
        ...state,
        securityPin: action.payload.securityPin,
      };
    },

    setSkipFlowSelection: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        skipFlowSelection: action.payload,
      };
    },
    clearAnswers: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const {
  clearState,
  setOnboardingAnswers,
  setCurrentOnboardingFlow,
  setSelectedFlows,
  setCreditLimit,
  switchFlows,
  updateSecurityPin,
  setSkipFlowSelection,
  clearAnswers,
  setOnboardingStatus,
  setOnboardingResponses,
} = answerSlice.actions;

export const selectAnswerState = (state: RootState): AnswerState => state.answer;
export const selectCurrentOnboardingFlow = (state: RootState): OnboardingType | undefined => state.answer.currentFlow;
export const selectAllOnboardingAnswers = (state: RootState) => state.answer.onboardingAnswers;
export const selectOnboardingAnswers = (state: RootState) => {
  if (state.answer.currentFlow === undefined) {
    return undefined;
  }
  return state.answer.onboardingAnswers[state.answer.currentFlow];
};
export const selectGeneralBankingOnboardingAnswers = (state: RootState) =>
  state.answer.onboardingAnswers[OnboardingType.GENERAL_BANKING];

export const selectCapitalMarketsOnboardingAnswers = (state: RootState) =>
  state.answer.onboardingAnswers[OnboardingType.CAPITAL_MARKETS];

export const selectPrivateBankingOnboardingAnswers = (state: RootState) =>
  state.answer.onboardingAnswers[OnboardingType.PRIVATE_BANKING];

export const selectDerivativesOnboardingAnswers = (state: RootState) =>
  state.answer.onboardingAnswers[OnboardingType.DERIVATIVES];

export const selectKardioOnboardingAnswers = (state: RootState) =>
  state.answer.onboardingAnswers[OnboardingType.KARDIO];

export const selectCapitalMarketsOnboardingResponses = (state: RootState) =>
  state.answer.onboardingResponses[OnboardingType.CAPITAL_MARKETS];

export const selectDerivativesOnboardingResponses = (state: RootState) =>
  state.answer.onboardingResponses[OnboardingType.DERIVATIVES];

export const selectPrivateBankingOnboardingResponses = (state: RootState) =>
  state.answer.onboardingResponses[OnboardingType.PRIVATE_BANKING];

export const selectGeneralBankingOnboardingResponses = (state: RootState) =>
  state.answer.onboardingResponses[OnboardingType.GENERAL_BANKING];

export const selectKardioOnboardingResponses = (state: RootState) =>
  state.answer.onboardingResponses[OnboardingType.KARDIO];

export const selectInvestmentOnboardingAnswers = (state: RootState) => {
  // Investment onboarding answers are onboarding answers that have similar fields
  // such as investorType, professionalDocuments. We want to exclude General Banking which is missing these fields
  if (
    state.answer.currentFlow === undefined ||
    state.answer.currentFlow === OnboardingType.GENERAL_BANKING ||
    state.answer.currentFlow === OnboardingType.KARDIO
  ) {
    return undefined;
  }
  return state.answer.onboardingAnswers[state.answer.currentFlow];
};

export const selectInvestorType = (state: RootState) => {
  const capitalMarketsAnswers = selectCapitalMarketsOnboardingAnswers(state);
  const privateBankingAnswers = selectPrivateBankingOnboardingAnswers(state);
  const answers = capitalMarketsAnswers || privateBankingAnswers;
  return answers?.investorType;
};

export const selectSelectedFlows = (state: RootState) => state.answer.selectedFlows;

export const selectCompletedFlows = (state: RootState) => state.answer.completedFlows;

export const selectSkipFlowSelection = (state: RootState) => state.answer.skipFlowSelection;

export const selectOnboardingStatus = (state: RootState) => state.answer.onboardingStatus;

export default answerSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { logout } from './session';

interface ModalState {
  showModal: boolean;
}

const initialState: ModalState = {
  showModal: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    displayModal: () => {
      return { showModal: true };
    },
    hideModal: () => {
      return { showModal: false };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { displayModal, hideModal } = modalSlice.actions;

export const selectShowModal = (state: RootState): boolean => state.modal.showModal;

export default modalSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { StringsDataMap } from '../types/Types';

interface StringState {
  data: StringsDataMap | undefined;
}

const initialState: StringState = {
  data: undefined,
};

export const stringSlice = createSlice({
  name: 'string',
  initialState,
  reducers: {
    updateStrings: (state, action: PayloadAction<StringsDataMap>) => {
      return { data: action.payload };
    },
  },
});

export const { updateStrings } = stringSlice.actions;

export const selectStrings = (state: RootState): StringState['data'] => {
  return state.string.data;
};

export default stringSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuestionError } from '@kvika/survey';
import { RootState } from '.';
import { logout } from './session';
import { ServiceStatusMode, ErrorData, ErrorType } from '../types/Types';

interface DisplayError {
  showErrorModal?: boolean;
  modalErrorBodyKey?: ErrorType;
  modalErrorHeaderKey?: ErrorType;
  errorStatus?: number;
  showApiErrors?: boolean;
  apiErrors?: QuestionError[];
  modalCustomErrorBodyText?: string;
}

interface ErrorState {
  showErrorModal?: boolean;
  modalErrorBodyKey?: ErrorType;
  modalErrorHeaderKey?: ErrorType;
  errorStatus?: number;
  showApiErrors?: boolean;
  apiErrors?: QuestionError[];
  error?: ErrorData;
  modalCustomErrorBodyText?: string;
  serviceStatus?: ServiceStatusMode;
}

const initialState: ErrorState = {
  showErrorModal: false,
  showApiErrors: false,
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    hideErrorModal: (state) => {
      return { ...state, showErrorModal: false };
    },
    displayError: (_, action: PayloadAction<DisplayError>) => {
      return {
        showErrorModal: action.payload.showErrorModal,
        modalErrorBodyKey: action.payload.modalErrorBodyKey,
        modalErrorHeaderKey: action.payload.modalErrorHeaderKey,
        errorStatus: action.payload.errorStatus,
        showApiErrors: action.payload.showApiErrors,
        apiErrors: action.payload.apiErrors,
        modalCustomErrorBodyText: action.payload.modalCustomErrorBodyText,
      };
    },
    updateError: (state, action: PayloadAction<ErrorData>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    updateServiceStatus: (state, action: PayloadAction<ServiceStatusMode>) => {
      return {
        ...state,
        serviceStatus: action.payload,
      };
    },
    clearServiceStatus: (state) => {
      return {
        ...state,
        serviceStatus: undefined,
      };
    },
    clearErrorState: (state) => {
      return {
        ...state,
        errorStatus: undefined,
        modalErrorBodyKey: undefined,
        modalErrorHeaderKey: undefined,
        showErrorModal: false,
        showApiErrors: false,
        apiErrors: undefined,
        modalCustomErrorBodyText: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { displayError, clearErrorState, hideErrorModal, updateError, updateServiceStatus, clearServiceStatus } =
  errorsSlice.actions;

export const selectShowErrorModal = (state: RootState): boolean => Boolean(state.errors.showErrorModal);
export const selectModalErrorBodyKey = (state: RootState): ErrorType | undefined => state.errors.modalErrorBodyKey;
export const selectModalErrorHeaderKey = (state: RootState): ErrorType | undefined => state.errors.modalErrorHeaderKey;
export const selectCustomErrorBodyText = (state: RootState): string | undefined =>
  state.errors.modalCustomErrorBodyText;
export const selectErrorStatus = (state: RootState): number | undefined => state.errors.errorStatus;
export const selectApiErrors = (state: RootState): QuestionError[] | undefined => state.errors.apiErrors;
export const selectShowApiErrors = (state: RootState): boolean | undefined => state.errors.showApiErrors;
export const selectError = (state: RootState): ErrorData | undefined => state.errors.error;
export const selectServiceStatus = (state: RootState): ServiceStatusMode | undefined => state.errors.serviceStatus;
export default errorsSlice.reducer;

import { UnknownObject } from '../types/Types';

declare const window: UnknownObject;
declare const global: UnknownObject;

export enum SegmentTrackingId {
  LoginStarted = 'User Login Inititated',
  LoginCompleted = 'User Login Completed',
  LoginFailed = 'User Login Failed',
  Logout = 'User Logged Out',
  LanguageChangedTo = 'Language Changed To XX',
  OnboardingStarted = ' User Onboarding Initiated',
  OnboardingCompleted = 'User Onboarding Completed',
  PersonalInformation = 'User Confirm Information',
  Categorization = 'Choose User Category',
}

export enum SegmentPageId {
  Home = 'Home',
}

export type AnalyticsEvent = {
  event: SegmentTrackingId | SegmentPageId;
  properties?: Record<string, unknown>;
};

export const trackPage = (url: string, properties?: Record<string, unknown>): void => {
  window.analytics.page(url, properties);
};

export const trackEvent = (analyticsEvent: AnalyticsEvent): void => {
  global.analytics.track(analyticsEvent.event, analyticsEvent.properties);
};

import * as React from 'react';
import { aCaslonPro, akzidenzGroteskPro } from '../styles/GlobalStyles';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const FontWrapper = ({ children }: Props) => {
  const fontClassName = `${akzidenzGroteskPro.variable} ${aCaslonPro.variable}`;
  // TODO: Change this to something other than main and update global styles
  return <main className={fontClassName}>{children}</main>;
};
export default FontWrapper;

import {
  CapitalMarketsOnboardingResponseSchema,
  DerivativesOnboardingResponseSchema,
  GeneralBankingOnboardingResponseSchema,
  InvestorTypeEnum,
  KardioOnboardingResponseSchema,
  Language,
  OnboardingCompanyInfoSchema,
  OnboardingType,
  PrivateBankingOnboardingResponseSchema,
} from '@kvika/api-types';
import { setTag, setUser } from '@sentry/nextjs';
import { ErrorData, Lang, Maybe, ModalFields } from '../types/Types';
import { getExternalId } from './AuthenticationStorage';
import { RichTextItem } from '../components/CustomRichText/CustomRichText';

export const getAllOnboardingTypes = (): string[] => {
  return Object.keys(OnboardingType).filter((item) => {
    return Number.isNaN(Number(item));
  });
};
export const getTier = (): 'production' | 'staging' => {
  const TIER = process.env.NEXT_PUBLIC_ONBOARDING_ENV || '';
  if (TIER === 'production') {
    return TIER;
  }
  return 'staging';
};

export const isProduction = (): boolean => getTier() === 'production';
export const isStaging = (): boolean => getTier() === 'staging';

const getTemplateVariableKeysWithCurlys = (templateVariables: Record<string, string>) => {
  const keys = Object.keys(templateVariables);
  return keys.map((key) => `{${key}}`);
};

export const getTextWithTemplateVariablesNew = (
  text: RichTextItem[],
  templateVariables: Record<string, string>
): RichTextItem[] => {
  const keys = getTemplateVariableKeysWithCurlys(templateVariables);
  const regex = new RegExp(keys.join('|'), 'g');

  return text.map((textItem) => {
    return {
      ...textItem,
      text:
        typeof textItem.text === 'string'
          ? textItem.text.replace(regex, (match) => {
              const matchWithoutCurly = match.replace('{', '').replace('}', '');
              return templateVariables[matchWithoutCurly] || match;
            })
          : textItem.text,
    };
  });
};

export const shouldDisplayClearAnswers = (isClearAnswersEnabled: boolean): boolean => {
  return isStaging() && isClearAnswersEnabled;
};

export type OnboardingStatus = {
  isComplete: boolean;
  isConfirmationBlocked: boolean;
  isPepBlocked: boolean;
  isRiskBlocked: boolean;
  isSubmitted: boolean;
  isPinSet: boolean;
  companyInfo?: OnboardingCompanyInfoSchema;
  investorType?: InvestorTypeEnum;
  actingRetail?: boolean;
  isNotFound?: boolean;
  isBeneficialOwnersBlocked?: boolean;
  isApprovalBlocked?: boolean;
};

export type OnboardingStatusState = {
  [key in OnboardingType]: OnboardingStatus | Record<string, unknown>;
};

export const getISOLang = (lang: Lang): Language => {
  switch (lang) {
    case Lang.IS:
      return Language.IsIS;
    case Lang.EN:
      return Language.EnUS;
    default:
      return Language.IsIS;
  }
};

export const isValidFileType = (file: File, allowedFileTypes: string[]) => {
  const splitByDots = file.name.split('.');
  const fileEnding = splitByDots[splitByDots.length - 1].toUpperCase();
  return allowedFileTypes.includes(fileEnding);
};

export const getIsDependantOnCapitalMarkets = (onboardingType: OnboardingType) =>
  onboardingType === OnboardingType.PRIVATE_BANKING || onboardingType === OnboardingType.DERIVATIVES;

export const getIsInvestmentFlowSelected = (onboardingTypes: OnboardingType[]) =>
  onboardingTypes.includes(OnboardingType.CAPITAL_MARKETS) ||
  onboardingTypes.includes(OnboardingType.PRIVATE_BANKING) ||
  onboardingTypes.includes(OnboardingType.DERIVATIVES);
export const getErrorEvent = (apiError: ErrorData, extra?: Record<string, unknown>) => {
  if (apiError) {
    const { status, data, headers } = apiError;
    if (data && headers) {
      const requestId = headers['x-request-id'];
      setTag('requestId', requestId);
      setUser({ id: getExternalId() });
      const { type, code, detail } = data;
      const detailString = typeof detail === 'string' ? detail : JSON.stringify(detail);
      return {
        message: `${detailString}, errorCode: ${status}}`,
        extra: { code, type, requestId, ...extra },
      };
    }
  }
  // Reset tag if we have a unexpected error so that it doesn't get the requestId tag from the previous error
  setTag('requestId', undefined);
  setUser(null);
  return { message: `An unexpected error occurred}`, extra };
};

export const getLegalEntityDescription = (name: string, ssn: string) => {
  return `${name}, kt. ${ssn} (einnig nefnt „félagið“) og Kvika banki hf., kt. 540502-2930 (einnig nefnt „bankinn“) hafa gert með sér samning um fyrirkomulags samþykktar/staðfestingar félagsins á framvirkum samningum sem það hefur gert við bankann. Fyrirkomulagið er með þeim hætti að framvirkur samningur telst samþykktur/staðfestur af hálfu viðkomandi ef ekki eru gerðar athugasemdir við efni hans innan tveggja sólarhringa frá þeim tíma sem bankinn sendi félaginu afrit samningsins með tölvupósti. Með því að smella á “Samþykkja” hér að neðan telst framvirkur samningur samþykktur/staðfestur af hálfu félagsins.`;
};

export const getDescription = (name: string, ssn: string) => {
  return `${name}, kt. ${ssn} (einnig nefnt „félagið“) og Kvika banki hf., kt. 540502-2930 (einnig nefnt „bankinn“) hafa gert með sér samning um fyrirkomulags samþykktar/staðfestingar félagsins á framvirkum samningum sem það hefur gert við bankann. Fyrirkomulagið er með þeim hætti að framvirkur samningur telst samþykktur/staðfestur af hálfu viðkomandi ef ekki eru gerðar athugasemdir við efni hans innan tveggja sólarhringa frá þeim tíma sem bankinn sendi félaginu afrit samningsins með tölvupósti. Með því að smella á “Samþykkja” hér að neðan telst framvirkur samningur samþykktur/staðfestur af hálfu félagsins.`;
};

export const getDerivativesDescription = (name: string) => {
  return `Fyrir hönd ${name}. („félagið“) staðfestist af hálfu undirritaðs/undirritaðra að félagið nálgaðist að eigin frumkvæði Kviku banka hf., kt. 540502-2930, Katrínartúni 2, 105 Reykjavík (hér eftir „Kvika“ eða „bankinn“) og óskaði eftir að eiga viðskipti með framvirka samninga með undirliggjandi skráð hlutabréf. Kvika hefur óskað eftir upplýsingum um þekkingu félagsins og reynslu til að eiga viðskipti með fyrrnefnda fjármálagerninga og hefur lagt mat á það hvort umrædd vara sé tilhlýðileg fyrir félagið í skilningi 45. gr. laga um markaði fyrir fjármálagerninga. Félagið hefur verið upplýst um að samkvæmt framangreindu mati telur bankinn að félagið sem viðskiptamaður hafi ekki nægilega þekkingu og reynslu til að eiga viðskipti með umrædda fjármálagerninga. Með vísan til alls framangreinds ráðlagði bankinn félaginu að eiga ekki viðskipti með framvirka samninga. Það staðfestist hér með fyrir hönd félagsins að engu að síður, þrátt fyrir niðurstöðu Kviku og þá áhættu sem fylgir fjárfestingu í umræddum fjármálagerningum, óski félagið eftir að eiga umrædd viðskipti. Þá staðfestist að félagið hafi verið upplýst um, og sé fullkomlega kunnugt um, eðli og áhættu framangreindra fjármálagerninga. Loks staðfestist að undirritaður/undirritaðir hafi fulla heimild til að skuldbinda félagið með undirritun sinni og taka fjárfestingarákvarðanir fyrir þess hönd.`;
};

export const getNewOnboardingModal = (
  modals: ModalFields[],
  key: InvestorTypeEnum | string,
  templateVariables?: Record<string, string>
): ModalFields | undefined => {
  const modal = modals?.find((modal) => modal.key === key);
  if (templateVariables && modal) {
    return {
      ...modal,
      modal_text: modal.modal_text,
    };
  }
  return modal;
};

export const getModalTextString = (phoneNumber: string) => {
  return `Vinsamlegast fylgist með boðum sem koma í síma ${phoneNumber}`;
};

export const getOnboardingData = (
  selectedFlow: OnboardingType | undefined,
  capitalMarketsOnboardingResponses: CapitalMarketsOnboardingResponseSchema | undefined,
  derivativesOnboardingResponses: DerivativesOnboardingResponseSchema | undefined,
  privateBankingOnboardingResponses: PrivateBankingOnboardingResponseSchema | undefined,
  generalBankingOnboardingResponses: GeneralBankingOnboardingResponseSchema | undefined,
  kardioOnboardingResponses: KardioOnboardingResponseSchema | undefined
) => {
  switch (selectedFlow) {
    case OnboardingType.CAPITAL_MARKETS:
      return capitalMarketsOnboardingResponses;
    case OnboardingType.DERIVATIVES:
      return derivativesOnboardingResponses;
    case OnboardingType.PRIVATE_BANKING:
      return privateBankingOnboardingResponses;
    case OnboardingType.GENERAL_BANKING:
      return generalBankingOnboardingResponses;
    case OnboardingType.KARDIO:
      return kardioOnboardingResponses;
    default:
      return null;
  }
};

export const getLoginModalText = (phoneNumber: string) => {
  return `Auðkenningarbeiðni hefur verið send í símanúmerið þitt ${phoneNumber}. Vinsamlega staðfestu innskráningu`;
};

export const parseQueryParamsFromRouterPath = (path: string): NodeJS.Dict<string[]> => {
  const query = path.split('?')[1];
  const queryParams = new URLSearchParams(query);
  const params: NodeJS.Dict<string[]> = {};
  queryParams.forEach((value, key) => {
    params[key] = value.split(',');
  });
  return params;
};

export const valueOrDefault = <T>(value: Maybe<T>, defaultValue: T): T => {
  return value || defaultValue;
};

export const getPathnameComponents = (pathname: string): string[] => {
  return pathname.split('/').filter((item) => item !== '');
};

const tokenKey = 'token';
const externalIdKey = 'externalId';

// Token
export const getAuthenticationToken = (): string => {
  return sessionStorage.getItem(tokenKey) || '';
};
export const setAuthenticationToken = (token: string): void => {
  sessionStorage.setItem(tokenKey, token);
};

// External ID for tracking
export const getExternalId = (): string => {
  return sessionStorage.getItem(externalIdKey) ?? '';
};
export const setExternalId = (externalId: string): void => {
  sessionStorage.setItem(externalIdKey, externalId);
};

export const clearSession = (): void => {
  sessionStorage.clear();
};

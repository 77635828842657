import { AppProps } from 'next/app';

import React, { FunctionComponent } from 'react';
import Modal, { ModalHeader, ModalBody, ModalSize } from '@kvika/modal';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { GlobalStyles } from '../styles/GlobalStyles';
import Page from '../components/Page';
import { useApiError } from '../hooks/useApiError';
import store from '../store';
import {
  clearErrorState,
  hideErrorModal,
  selectApiErrors,
  selectCustomErrorBodyText,
  selectError,
  selectModalErrorBodyKey,
  selectModalErrorHeaderKey,
  selectShowApiErrors,
  selectShowErrorModal,
} from '../store/error';
import { selectSessionState } from '../store/session';
import { selectStrings } from '../store/string';
import { getStringFromKey } from '../utils/Languages';
import { ErrorType } from '../types/Types';
import FontWrapper from '../components/FontWrapper';

const GlobalModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(selectShowErrorModal);
  const modalErrorBodyKey = useSelector(selectModalErrorBodyKey);
  const modalErrorHeaderKey = useSelector(selectModalErrorHeaderKey);
  const modalCustomErrorBodyText = useSelector(selectCustomErrorBodyText);
  const showApiErrors = useSelector(selectShowApiErrors);
  const apiErrors = useSelector(selectApiErrors);
  const { lang } = useSelector(selectSessionState);
  const strings = useSelector(selectStrings);

  const getModalTitle = () => {
    if (showApiErrors) return getString(ErrorType.ERROR_INVALID_INPUT_IN_FORM);

    return modalErrorHeaderKey ? getString(modalErrorHeaderKey) : getString(ErrorType.ERROR_OCCURRED);
  };

  const getString = (key?: ErrorType) => {
    if (strings && key) {
      return getStringFromKey({ lang, strings, key });
    }
    return undefined;
  };

  const onClose = () => {
    dispatch(hideErrorModal());
  };

  const renderModalBody = () => {
    if (showApiErrors && apiErrors) {
      return (
        <div>
          {apiErrors.map((error) => (
            <p key={`${error.questionId}-${error.personIndex}-${error.questionProperty}`}>{error.errorMessage}</p>
          ))}
        </div>
      );
    }
    if (modalCustomErrorBodyText) {
      return <div>{modalCustomErrorBodyText}</div>;
    }

    return <>{getString(modalErrorBodyKey)}</>;
  };

  return (
    <Modal
      onExitAnimationEnd={() => dispatch(clearErrorState())}
      size={ModalSize.SMALL}
      isVisible={showModal}
      onClose={onClose}
    >
      {/* Workaround needed because main styles are not being applied here, probably because modal is a portal */}
      {/* TODO: Find a better way to apply these styles, f.ex. creating a styled component wrapper that just applies these styles */}
      <FontWrapper>
        <ModalHeader>{getModalTitle()}</ModalHeader>
        <ModalBody>{renderModalBody()}</ModalBody>
      </FontWrapper>
    </Modal>
  );
};

const ErrorHandler: FunctionComponent = () => {
  const error = useSelector(selectError);
  useApiError(error);
  return null;
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  // TODO: For some reason when using query params, a blank screen appears
  // This seems to be a known issue in PersistGate that hasn't been fixed https://github.com/rt2zz/redux-persist/issues/1232
  // According to some of the comments migrating to https://github.com/kirill-konshin/next-redux-wrapper solved the problem
  return (
    <Provider store={store}>
      <FontWrapper>
        <GlobalStyles />

        <PersistGate loading={null} persistor={persistStore(store)}>
          <Page>
            <GlobalModal />
            <ErrorHandler />
            <Component {...pageProps} />
          </Page>
        </PersistGate>
      </FontWrapper>
    </Provider>
  );
};

export default MyApp;

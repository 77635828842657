import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressSchema } from '@kvika/api-types';
import { RootState } from '.';
import { Lang } from '../types/Types';

export interface LoginSuccessfulPayload {
  ssn: string;
  name: string;
  email: string;
  address: AddressSchema;
  phoneNumber: string;
}

interface SessionState {
  loggedIn: boolean;
  kardioLoggedIn: boolean;
  redirectPath: string;
  user: {
    ssn: string;
    name: string;
    email: string;
    address: AddressSchema;
    phoneNumber: string;
  };
  lang: Lang;
}

const initialState: SessionState = {
  loggedIn: false,
  kardioLoggedIn: false,
  redirectPath: '/',
  user: {
    ssn: '',
    name: '',
    email: '',
    address: {
      country: { id: '', nameEN: '', nameIS: '', allowTax: false },
      isLivingAbroad: false,
    },
    phoneNumber: '',
  },
  lang: Lang.IS,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loginSuccessful: (state, action: PayloadAction<LoginSuccessfulPayload>) => {
      return {
        ...state,
        loggedIn: true,
        redirectPath: '/',
        user: action.payload,
      };
    },
    kardioLogIn: (state) => {
      return {
        ...state,
        kardioLoggedIn: true,
        redirectPath: '/kardio',
      };
    },
    logout: (state) => {
      return {
        ...initialState,
        redirectPath: state.redirectPath,
      };
    },
    updateFundName(state, action: PayloadAction<string>) {
      return { ...state, fundName: action.payload };
    },
  },
});

export const { loginSuccessful, kardioLogIn, logout, updateFundName } = sessionSlice.actions;

export const selectSessionState = (state: RootState): SessionState => state.session;

export default sessionSlice.reducer;
